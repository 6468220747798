<template>
    <div class="social-btn__wrapper">
        <ShareNetwork network="facebook" :title="title" :url="currentHref">
            <CRButton class="mr-8" facebook />
        </ShareNetwork>
        <ShareNetwork network="twitter" :title="title" :url="currentHref">
            <CRButton class="mr-8" twitter />
        </ShareNetwork>
        <CRButton @click="copyLink" copy />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'SocialShareBlock',
        components: { CRButton },
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        computed: {
            currentHref() {
                return window.location.href;
            },
        },
        methods: {
            async copyLink() {
                try {
                    await navigator.clipboard.writeText(this.currentHref);
                    this.$filters.toast(this.$t('common.linkCopied'), { type: TYPE.SUCCESS });
                } catch (error) {
                    this.$filters.toast(this.$t('common.cantCopy'));
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .social-btn__wrapper {
        @include row-align-center;
    }
</style>
