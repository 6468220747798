<template>
    <div class="slider-card" :style="backgroundStyles(image?.picturePath.inner)">
        <SocialShareBlock v-if="withSocialIcons" />
        <CRTag v-if="isVegetarian" type="secondary">
            {{ $t('common.havePescatarian') }}
        </CRTag>
        <div class="discount" v-if="discountAmount && discountType">
            <h2>{{ getDiscountDisplay(discountAmount, discountType) }}</h2>
        </div>
    </div>
</template>

<script>
    import SocialShareBlock from '@/components/Common/SocialShareBlock/SocialShareBlock.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { getDiscountDisplay } from '@/helpers/MealKitHelper';

    export default {
        name: 'SliderCard',
        components: { CRTag, SocialShareBlock },
        props: {
            image: {
                type: Object,
                default: () => {},
            },
            discountType: {
                type: String,
                default: null,
            },
            discountAmount: {
                type: Number,
                default: null,
            },
            isVegetarian: {
                type: Boolean,
                default: false,
            },
            withSocialIcons: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                backgroundStyles,
                getDiscountDisplay,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .slider-card {
        @include column-align-end-justify-between;

        padding-top: 90px;
        padding-right: 10px;
        padding-bottom: 10px;

        height: 300px;

        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        position: relative;

        background-image: url('../../assets/images/backgrounds/empty-mealKit-bg.png');

        .discount {
            position: absolute;
            bottom: 0;
            left: 0;

            padding: 5px 17px;

            box-shadow: $box-shadow-dark;
            background-color: $white;

            border-bottom-left-radius: $br-15;
            border-top-right-radius: $br-15;

            h2 {
                color: $danger;
            }
        }
    }
</style>
