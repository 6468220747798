<template>
    <div class="macronutrient-container" v-if="calorie">
        <div class="color-banner" />
        <div class="inner-content">
            <h2 class="title mt-20 mb-16">{{ calorie.amount }} kcal</h2>
            <div class="element mb-16">
                <p class="field">{{ $t('common.carbohydrates') }}</p>
                <h3 class="field-value">{{ calorie.averageCarbohydrates ?? '-' }}</h3>
            </div>
            <div class="element mb-16">
                <p class="field">{{ $t('common.proteins') }}</p>
                <h3 class="field-value">{{ calorie.averageProteins ?? '-' }}</h3>
            </div>
            <div class="element mb-16">
                <p class="field">{{ $t('common.fats') }}</p>
                <h3 class="field-value">{{ calorie.averageFats ?? '-' }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Macronutrient',
        props: {
            calorie: {
                type: Object,
                default: () => {},
            },
            color: {
                type: String,
                default: '#00A755',
            },
        },
    };
</script>

<style scoped lang="scss">
    .macronutrient-container {
        width: 200px;

        border-radius: $br-10;

        box-shadow: $box-shadow-dark;

        .color-banner {
            height: 15px;
            background-color: v-bind(color);

            box-shadow: 0px 0 3px rgba(0, 0, 0, 0.35);

            border-top-left-radius: $br-10;
            border-top-right-radius: $br-10;
        }

        .inner-content {
            padding: 0 15px;
        }

        @include media($lg) {
            width: 300px;
        }
    }
</style>
