<template>
    <div class="description-section">
        <div class="tag__wrapper">
            <CRTag class="tag"> {{ mealsAmount }} {{ $t(mealLabel) }} </CRTag>
            <CRTag class="tag" v-if="withCombinations" type="secondary">
                {{ $t('mealKits.customMealSelection') }}
            </CRTag>
            <CRTag class="tag" v-else v-for="meal in meals" :key="meal.id" type="secondary">
                {{ $t(`common.${meal.type}`) }}
            </CRTag>
        </div>
        <div class="mb-16" v-html="description" />
        <CRButton @click="$emit('openCart')">{{ $t('buttons.order') }}</CRButton>
    </div>
</template>

<script>
import CRTag from '@/components/Common/Tags/CRTag.vue';
import CRButton from '@/components/Common/Buttons/CRButton.vue';

export default {
    name: 'DescriptionSection',
    components: { CRTag, CRButton },
    props: {
        description: {
            type: String,
            default: '',
        },
        mealsAmount: {
            type: [Number, String],
            default: 0,
        },
        meals: {
            type: Array,
            default: () => [],
        },
        withCombinations: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        mealLabel() {
            return `common.${this.mealsAmount === 1 ? 'meal' : this.mealsAmount > 1 && this.mealsAmount < 10 ? 'meals' : 'meals_plural'}`;
        }
    },
    emits: ['openCart'],
};
</script>

<style lang="scss" scoped>
.description-section {
    .tag__wrapper {
        @include row-align-center;
        flex-wrap: wrap;

        margin-bottom: 6px;

        .tag {
            margin-right: 8px;
            margin-bottom: 10px;
        }
    }
}
</style>
