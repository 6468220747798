<template>
    <div class="chart-wrap">
        <h2 class="mb-8">{{ $t('common.macronutrients') }}</h2>
        <h5>{{ $t('mealKits.comparisonOfAvailableCalorieChoices') }}</h5>
        <div class="chart-container">
            <VueApexCharts id="chart" type="donut" width="380" :options="chartOptions" :series="caloriesValues" />
            <div class="macronutrient__container">
                <Macronutrient
                    class="macronutrient"
                    v-for="(calorie, index) in currentMealKit.calories"
                    :key="calorie.id"
                    :calorie="calorie"
                    :color="getColor(index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue3-apexcharts';
    import Macronutrient from '@/components/MealKits/Macronutrient.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'MacronutrientChart',
        components: {
            VueApexCharts,
            Macronutrient,
        },

        data() {
            return {
                Macronutrient,
                colors: ['#00595a', '#00a755', '#f67c00', '#ffb316'],
                chartOptions: {
                    colors: [],
                    chart: {
                        width: 380,
                        type: 'donut',
                        selection: {
                            enabled: false,
                        },
                    },
                    labels: [],
                    dataLabels: {
                        enabled: false,
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                    legend: {
                        show: false,
                    },
                },
            };
        },
        computed: {
            ...mapGetters('mealKit', ['currentMealKit']),

            caloriesValues() {
                return this.currentMealKit.calories.map((calorie) => calorie.amount);
            },
        },

        methods: {
            getColor(calorieIndex) {
                const color =
                    this.colors[calorieIndex] ?? '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');

                this.chartOptions.colors.push(color);

                return color;
            },
        },
        mounted() {
            this.chartOptions.labels = this.currentMealKit.calories.map((calorie) => {
                return this.$filters.getTranslationByKey(calorie.macronutrient.name).replace(/[0-9]/g, '');
            });
        },
    };
</script>

<style scoped lang="scss">
    h5 {
        margin-bottom: 37px;
        color: $grey-static;
        max-width: 472px;
    }

    .chart-wrap {
        width: 100%;
    }

    .chart-container {
        @include column-align-center;

        .macronutrient__container {
            @include row-align-center;
            flex-wrap: wrap;

            width: 100%;

            .macronutrient {
                width: 100%;

                margin-bottom: 20px;
            }
        }
    }

    @include media($sm) {
        .chart-container {
            .macronutrient__container {
                @include row-align-center;

                .macronutrient {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($lg) {
        .chart-container {
            @include row-align-center;
        }
    }

    @include media($xl) {
        .chart-container {
            .macronutrient__container {
                .macronutrient {
                    width: 32%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
